import { useSingleQuery } from '@/data/hook/query/useGetManualQuery';
import useManualQuery from '@/data/hook/query/useManualQuery';
import {
  deleteProjectUpvote,
  ProjectFilters,
  upvoteProject,
} from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { Project } from '@/models/Project';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useUpvoting(
  project: Project,
  filters?: ProjectFilters,
  invalidate: boolean = true,
) {
  const { upVoted, upVotes, id } = project;
  const queryClient = useQueryClient();
  const { update } = useManualQuery(
    projectsQuerykeys.list(filters)._ctx.infinity,
  );
  const { setData } = useSingleQuery(projectsQuerykeys.get(id));

  const onUpvote = useCallback(async () => {
    if (upVoted) {
      await deleteProjectUpvote(id);
      return upVotes - 1;
    } else {
      await upvoteProject(id);
      return upVotes + 1;
    }
  }, [id, upVoted, upVotes]);

  const { mutate: upVote, isLoading: isUpvoting } = useMutation(onUpvote, {
    async onSuccess(upVotes) {
      invalidate && queryClient.invalidateQueries(projectsQuerykeys.list._def);
      update({ ...project, upVoted: !upVoted, upVotes });
      setData({ ...project, upVoted: !upVoted, upVotes });
    },
    onError: err => alert.error(getErrorMessage(err)),
  });

  return [upVote, isUpvoting] as const;
}

export const useVoteStyles = (upVoted: boolean) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.card.actions',
  });
  const variants = {
    unvote: {
      color: 'text-error',
      text: t('unvote'),
      Icon: XIcon,
    },
    vote: {
      color: 'text-primary',
      text: t('vote'),
      Icon: CheckIcon,
    },
  };
  return variants[upVoted ? 'unvote' : 'vote'];
};
