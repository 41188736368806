import { Fragment } from 'react/jsx-runtime';
import CourseTypeFilter from './CourseTypeFilter';
import { CarouselFilterProps } from './CarouselFilterProps';
import { ProjectsSections } from '../ProjectCarousel';
import ProjectPeriodFilter from './ProjectPeriodFilter';

type SectionFilterFactoryProps = {
  section?: ProjectsSections;
} & CarouselFilterProps;

const SectionFilterFactory = ({
  section,
  ...props
}: SectionFilterFactoryProps) => {
  if (section === 'recents') {
    return <CourseTypeFilter {...props} />;
  }
  if (section === 'hackathon') {
    return <CourseTypeFilter {...props} />;
  }
  if (section === 'me') {
    return <Fragment />;
  }
  if (section) {
    return <ProjectPeriodFilter {...props} />;
  }

  return <Fragment />;
};

export default SectionFilterFactory;
