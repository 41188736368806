import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import FlexGroup from './FlexGroup';
import { ProjectTags } from './ProjectTags';
import Banner from './Banner';
import Interactions from './Interactions';
import PublishDate from './PublishDate';
import Owners from './Owners';
import { Project } from '@/models/Project';
import Title from './Title';
import HackathonVoting from './HackathonVoting';
import OwnerActions from './OwnerActions';
import { twMerge } from 'tailwind-merge';
import CardSkeleton from './Skeleton';
import { ROUTES } from '@/utils/routes';

export default function Card({
  children,
  className,
  project,
}: PropsWithChildren<{ project: Project; className?: string }>) {
  return (
    <li
      className={twMerge(
        'relative p-2 rounded-lg min-w-72 w-72 xl:min-w-80 bg-base-100 shadow-default snap-center sm:snap-none border border-neutral-content',
        className,
      )}
    >
      <Link
        to={ROUTES.PROJECTS.INFO(project.id)}
        state={project}
        className="flex flex-col h-full gap-2.5"
      >
        {children}
      </Link>
    </li>
  );
}

Card.FlexGroup = FlexGroup;
Card.Tags = ProjectTags;
Card.Banner = Banner;
Card.Title = Title;
Card.Interactions = Interactions;
Card.PublushDate = PublishDate;
Card.Owners = Owners;
Card.HackathonVoting = HackathonVoting;
Card.OwnerActions = OwnerActions;
Card.Skeleton = CardSkeleton;
