import SelectFilter from '@/components/filter/SelectFilter';
import { FilterOption } from '@/models/FilterConfig';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  CarouselFilterProps,
  CoursePeriodFilterValue,
} from './CarouselFilterProps';

const format = 'YYYY-MM-DD';

const mothRange = () => {
  const dateAfter = moment().subtract({ month: 1 }).format(format);
  const dateBefore = moment().format(format);

  return { dateAfter, dateBefore };
};
const weekRange = () => {
  const dateAfter = moment().subtract({ week: 1 }).format(format);
  const dateBefore = moment().format(format);

  return { dateAfter, dateBefore };
};
const ProjectPeriodFilter = ({ onFilter, filter }: CarouselFilterProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.page.filters.period',
  });

  const onChange = (period: CoursePeriodFilterValue) => {
    const periodRanges = {
      all: { dateAfter: undefined, dateBefore: undefined },
      lastWeek: weekRange(),
      lastMoth: mothRange(),
    };
    onFilter?.({ ...periodRanges[period], period });
  };

  const options: FilterOption<CoursePeriodFilterValue>[] = [
    {
      label: t('allTime'),
      value: 'all',
    },
    {
      label: t('lastWeek'),
      value: 'lastWeek',
    },
    {
      label: t('lastMonth'),
      value: 'lastMoth',
    },
  ];
  const value = filter?.period || 'all';

  return (
    <SelectFilter
      name="period"
      options={options}
      className={{
        reference:
          'bg-base-100 rounded-full py-0.5 px-1 text-14 border border-neutral-content',
        text: 'text-primary font-500',
      }}
      onClear={() => onChange('all')}
      value={value}
      type="select"
      showOnClear={value !== 'all'}
      setValue={(_, value) => onChange(value as CoursePeriodFilterValue)}
    />
  );
};

export default ProjectPeriodFilter;
