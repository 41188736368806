import Tag from '@/components/common/dataDisplay/Tag';
import Text from '@/components/common/dataDisplay/Text';
import { CircleIcon, DashedCircleIcon } from '@/components/icons';
import { HackathonStage } from '@/models/Hackathon';
import { getStageStatus } from '@/utils/hackathon';
import { CheckCircleIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type HackathonStagesProps = {
  hackathonStages: HackathonStage[];
};

export default function HackathonStages({
  hackathonStages,
}: HackathonStagesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hackathon',
  });
  const stagesOrdened = hackathonStages.sort(
    (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf(),
  );
  return (
    <div className="flex flex-col gap-1.5">
      <Text text={t('steps')} className="leading-7 font-600" />
      <ul className="flex flex-col border-l border-warning pl-2.5 gap-1.5">
        {stagesOrdened.map(hackathonStage => (
          <StageItem hackathonStage={hackathonStage} key={hackathonStage.id} />
        ))}
      </ul>
    </div>
  );
}

type StageItemProps = {
  hackathonStage: HackathonStage;
};

const StageItem = ({ hackathonStage }: StageItemProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hackathon.stages',
  });
  const statusConfig = {
    complete: {
      className: 'line-through text-hackathon-secondary',
      icon: CheckCircleIcon,
    },
    future: {
      className: '',
      icon: DashedCircleIcon,
    },
    progress: {
      className: 'text-hackathon-primary',
      icon: CircleIcon,
    },
  };

  const { icon: Icon, className } =
    statusConfig[getStageStatus(hackathonStage)];
  return (
    <li>
      <Tag
        text={t(hackathonStage.type)}
        color="custom"
        className={twMerge(
          'gap-1 flex-row-reverse bg-none p-0 font-400',
          className,
        )}
        icon={<Icon className="w-4 h-4 shrink-0" />}
      />
    </li>
  );
};
