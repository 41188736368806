import { ProjectFilters } from '@/data/services/projectServices';
import { Project } from '@/models/Project';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { EyeIcon, HeartIcon } from '@heroicons/react/outline';
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/solid';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import Interaction from './Interaction';
import useUpvoting from './Interactions.hooks';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { useTranslation } from 'react-i18next';

type InteractionContainerProps = {
  project: Project;
  className?: Pick<ClassNameComponent, 'base' | 'tag' | 'img'>;
  filters?: ProjectFilters;
};

export default function Interactions({
  project,
  className,
  filters,
}: PropsWithChildren<InteractionContainerProps>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.card',
  });
  const { upVoted, upVotes, views } = project;

  const [upVote, isUpvoting] = useUpvoting(project, filters);

  const UpVotedIcon = upVoted ? SolidHeartIcon : HeartIcon;
  return (
    <div
      className={twMerge(
        'absolute right-3 top-3 flex items-center gap-1 text-base-content',
        className?.base,
      )}
      data-testid="interactionsCard"
    >
      <Tooltip text={t('views')}>
        <Interaction
          count={views}
          color="primary"
          className={className}
          icon={EyeIcon}
        />
      </Tooltip>

      <Tooltip text={t('likes')}>
        <Interaction
          count={upVotes}
          icon={UpVotedIcon}
          className={className}
          color="error"
          isLoading={isUpvoting}
          onClick={upVote}
          testId="upvoteButton"
          whileHover={{
            scale: 1.1,
          }}
        />
      </Tooltip>
    </div>
  );
}
