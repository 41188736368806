import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Hackathon, { HackathonStage } from '@/models/Hackathon';
import { Breakpoints } from '@/utils/Breakpoint';
import { useMediaQuery } from 'react-responsive';
import HackathonBannerInformation from './HackathonBannerInformation';
import HackathonCountdown from './HackathonCountdown';
import HackathonStages from './HackathonStages';

type HackathonBannerProps = {
  hackathon: Hackathon;
  hackathonStages: HackathonStage[];
};

export default function HackathonBanner({
  hackathon,
  hackathonStages = [],
}: HackathonBannerProps) {
  const isLg = useMediaQuery({
    minWidth: Breakpoints.lg,
  });
  return (
    <div className="outline-offset-4 outline outline-1 outline-hackathon-primary rounded-lg border border-hackathon-secondary flex flex-col md:flex-row gap-y-3 px-2.5 py-1 items-center justify-between">
      <HackathonBannerInformation hackathon={hackathon} />
      <div className="flex gap-3">
        <HackathonCountdown />
        <ConditionalRenderer condition={hackathonStages.length && isLg}>
          <HackathonStages hackathonStages={hackathonStages} />
        </ConditionalRenderer>
      </div>
    </div>
  );
}
