import ResourceFileUpload from '@/components/admin/lessons/resource/form/ResourceFileUpload';
import MainButton from '@/components/common/buttons/MainButton';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import EmbedPreview from '@/components/common/dataDisplay/EmbedPreview';
import ChoiceSelector from '@/components/common/dataInput/ChoiceSelector';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import TextInput from '@/components/common/dataInput/TextInput';
import MyCkeditor from '@/components/editor/MyCkeditor';
import TopicsContainer from '@/components/topics/TopicsContainer';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import { getProjectData } from '@/data/services/projectServices';
import {
  flagsQueryKeys,
  toolsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { listTools } from '@/data/services/toolServices';
import { isStudent, isTeacher } from '@/functions/auth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { ImageFolderEnum } from '@/models/CkEditor';
import { CourseTypeEnum } from '@/models/Course';
import Flag from '@/models/Flag';
import { Project } from '@/models/Project';
import User, { UserTypeEnum } from '@/models/User';
import cleanupObj from '@/utils/cleanupObj';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import useHackathon from '../hackathon/HackathonGeneral.hooks';
import ProjectHackathonForm from '../hackathon/ProjectHackathonForm';
import ProjectImport from '../hackathon/ProjectImport';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '@/utils/Breakpoint';
import Text from '@/components/common/dataDisplay/Text';

export type ProjectFields = Project & {
  imageFile?: File;
  courseFlag?: Flag;
  isHackathon?: boolean;
};
type SubmitFn = (project: ProjectFields) => void;

type ProjectFormProps = {
  project?: Project;
  isLoading?: boolean;
  saveButtonText?: string;
  onSubmit: SubmitFn;
  hasChanges?: boolean;
  setHasChanges?: (value: boolean) => void;
};

export default function ProjectForm({
  project,
  onSubmit,
  saveButtonText,
  setHasChanges,
  hasChanges,
  isLoading,
}: ProjectFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.fields',
  });

  const isMdScreen = useMediaQuery({
    minWidth: Breakpoints.md,
  });
  const [tCourseType] = useTranslation('translation', {
    keyPrefix: 'courseType',
  });
  const { hackathon } = useHackathon();

  const { user } = useAuth();

  const isStudentUser = isStudent(user?.userType);
  const isTeacherUser = isTeacher(user?.userType);

  const defaultValues = useMemo(
    () => ({
      ...project,
      teacherMentor:
        project?.teacherMentor || (isTeacherUser ? user : undefined),
      users: project?.users || (isStudentUser ? [user] : []),
      courseFlag: project?.flags?.filter(v =>
        Object.values<string>(CourseTypeEnum).includes(v.name),
      )?.[0],
      isHackathon: project?.flags?.some(flag => flag.id === hackathon?.flagId),
    }),
    [project, isStudentUser, user, hackathon, isTeacherUser],
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<ProjectFields>({
    defaultValues,
  });
  const isHackathon = watch('isHackathon') ?? false;

  const { field: usersField } = useController({
    name: 'users',
    control,
    rules: {
      required: t('required'),
    },
  });
  const { field: teacherField } = useController({
    name: 'teacherMentor',
    control,
    rules: {
      required: isHackathon ? t('required') : false,
    },
  });
  const { field: toolField } = useController({
    name: 'tool',
    control,
    rules: {
      required: t('required'),
    },
  });
  const { field: courseFlagField } = useController({
    name: 'courseFlag',
    control,
  });
  const { field: descriptionField } = useController({
    name: 'description',
    control,
    rules: {
      required: t('required'),
    },
  });
  const { field: imageField } = useController({
    name: 'image',
    control,
  });

  const handleImport = async (projectUrl: string) => {
    const { results } = await listTools({ site: projectUrl });
    const tool = results.at(0);
    if (!tool) {
      throw new Error('Could not find the tool used in the project');
    }
    const metadata = await getProjectData({ tool: tool.name, url: projectUrl });
    const fields = watch();
    reset({ ...fields, ...metadata, projectUrl, tool });
  };

  const { mutate: onImport, isLoading: isImporting } = useMutation(
    handleImport,
    {
      onError: err => setError('projectUrl', { message: getErrorMessage(err) }),
    },
  );

  const projectUrl = watch('projectUrl');

  useEffect(() => {
    const subscription = watch(fields => {
      setHasChanges?.(!isEqual(cleanupObj(fields), cleanupObj(defaultValues)));
    });
    return () => subscription.unsubscribe();
  }, [watch, setHasChanges, defaultValues]);

  const onSubmitClean: SubmitFn = fields => onSubmit(fields);

  useEffect(() => {
    const isHackathon = project?.flags?.some(
      flag => flag.id === hackathon?.flagId,
    );
    isHackathon && setValue('isHackathon', isHackathon);
  }, [hackathon, project, setValue]);

  return (
    <form
      className="gap-3.5 w-full relative md:min-h-[calc(100vh-140px)] md:flex-row md:flex"
      onSubmit={handleSubmit(onSubmitClean)}
    >
      <ConditionalRenderer condition={!isMdScreen}>
        <div className="flex gap-2.5 flex-wrap mb-2.5">
          <ProjectImport
            onImport={onImport}
            control={control}
            isLoading={isImporting}
            projectUrl={projectUrl}
          />
          <ComponentGuard
            roles={[
              UserTypeEnum.TEACHER,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.SUPER_ADMIN,
            ]}
          >
            <ProjectHackathonForm
              description={watch('description')}
              isHackathon={isHackathon}
              setIsHackathon={value => setValue('isHackathon', value)}
            />
          </ComponentGuard>
        </div>
      </ConditionalRenderer>
      <TopicsContainer className="md:w-60 xl:w-96 lg:w-72 flex-shrink-0 w-full flex flex-col gap-3 md:h-[calc(100vh-140px)]">
        <ImageUploader
          value={imageField.value}
          onChange={(url, file) => {
            imageField.onChange(url);
            setValue('imageFile', file);
          }}
          label={t('banner.placeholder')}
          swapText={t('banner.swapText')}
        />
        <ConditionalRenderer condition={isHackathon}>
          <TextInput
            label={t('equipName.label')}
            placeholder={t('equipName.placeholder')}
            labelPosition="top"
            className={{
              label: 'text-14 mb-1.5',
              input: 'w-full',
            }}
            {...register('equipName')}
          />
        </ConditionalRenderer>
        <ComponentGuard
          roles={[
            UserTypeEnum.UNIT_ADMIN,
            UserTypeEnum.TEACHER,
            UserTypeEnum.SUPER_ADMIN,
          ]}
        >
          <ChoiceSelector
            service={usersQueryKeys.list}
            getKey={user => user.id}
            displayName={handleUserFullName}
            filters={{
              userType: [UserTypeEnum.STUDENT],
              unitId: getAuthorizedUnits(user),
            }}
            render={ChoiceSelectRender}
            options={{ staleTime: REQUEST_STALE_TIME_IN_MS }}
            defaultValue={usersField.value}
            input={{
              label: t('users.label'),
              placeholder: t('users.placeholder'),
              className: {
                label: 'text-14 mb-1.5',
                input: 'w-full',
              },
              errorLabelText: errors.users?.message,
            }}
            onChange={usersField.onChange}
          />
        </ComponentGuard>
        <ComponentGuard
          roles={[UserTypeEnum.UNIT_ADMIN, UserTypeEnum.SUPER_ADMIN]}
        >
          <ConditionalRenderer condition={isHackathon}>
            <InfiniteSearchInput
              displayName={teacher => handleUserFullName(teacher)}
              service={usersQueryKeys.list}
              filters={{
                unitId: getAuthorizedUnits(user),
                userType: [UserTypeEnum.TEACHER],
              }}
              selectedItem={teacherField.value}
              onSelect={teacherField.onChange}
              disabled={isStudentUser}
              input={{
                label: t('teacherMentor.label'),
                testId: 'userInput',
                className: {
                  label: 'text-14 mb-1.5',
                  input: 'w-full',
                },
                placeholder: t('teacherMentor.placeholder'),
                disabled: isStudentUser,
                errorLabelText: errors.teacherMentor?.message,
              }}
              className={{ base: 'max-w-lg' }}
              options={{ staleTime: REQUEST_STALE_TIME_IN_MS }}
            />
          </ConditionalRenderer>
        </ComponentGuard>
        <InfiniteSearchInput
          displayName={tool => tool.name}
          service={toolsQueryKeys.list}
          selectedItem={toolField.value}
          onSelect={toolField.onChange}
          onDeselect={() => toolField.onChange(undefined)}
          input={{
            label: t('tool.label'),
            testId: 'toolSelect',
            className: {
              label: 'text-14 mb-1.5',
              input: 'w-full',
            },
            errorLabelText: errors.tool?.message,
            placeholder: t('tool.placeholder'),
          }}
          className={{ input: 'max-w-lg' }}
          options={{ staleTime: REQUEST_STALE_TIME_IN_MS }}
        />
        <InfiniteSearchInput
          displayName={tool => tCourseType(tool.name)}
          service={flagsQueryKeys.list}
          selectedItem={courseFlagField.value}
          onSelect={courseFlagField.onChange}
          onDeselect={() => courseFlagField.onChange(undefined)}
          filters={{
            name: Object.values(CourseTypeEnum).join(),
          }}
          input={{
            label: t('course.label'),
            className: { label: 'text-14 mb-1.5', input: 'w-full' },
            errorLabelText: errors.tool?.message,
            placeholder: t('course.placeholder'),
          }}
          className={{ input: 'max-w-lg' }}
          options={{ staleTime: REQUEST_STALE_TIME_IN_MS }}
        />
      </TopicsContainer>

      <TopicsContainer className="flex flex-col flex-grow gap-3 border-none h-full bg-transparent py-0">
        <ConditionalRenderer condition={isMdScreen}>
          <div className="flex gap-2.5 flex-wrap lg:flex-nowrap">
            <ProjectImport
              onImport={onImport}
              control={control}
              isLoading={isImporting}
              projectUrl={projectUrl}
            />
            <ComponentGuard
              roles={[
                UserTypeEnum.TEACHER,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.SUPER_ADMIN,
              ]}
            >
              <ProjectHackathonForm
                description={watch('description')}
                isHackathon={isHackathon}
                setIsHackathon={value => setValue('isHackathon', value)}
              />
            </ComponentGuard>
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={!!projectUrl}>
          <EmbedPreview
            className={{
              base: 'h-80 xl:h-96 aspect-video bg-primary-content flex justify-center rounded-lg items-center p-4 max-w-full',
              iframe: 'aspect-video h-full w-full rounded-md',
            }}
            src={projectUrl}
          />
        </ConditionalRenderer>
        <TextInput
          label={t('title.label')}
          placeholder={t('title.placeholder')}
          className={{ base: 'max-w-96', label: 'mb-1.5' }}
          errorLabelText={errors.title?.message}
          {...register('title', {
            required: t('required'),
          })}
        />
        <div className="relative w-full flex-col flex gap-1.5">
          <MyCkeditor
            content={descriptionField.value || ''}
            folder={ImageFolderEnum.PROJECT}
            handleContentChange={descriptionField.onChange}
            placeholder={t('description.placeholder')}
          />
          <Text
            text={errors.description?.message}
            className="text-error text-14"
          />
        </div>
        <SaveCancelGroup
          className="justify-end"
          loading={isLoading}
          save={{
            text: saveButtonText,
            type: 'submit',
            disabled: !hasChanges || !isEmpty(errors),
          }}
          cancel={{
            onClick: () => {
              reset(defaultValues);
              setHasChanges?.(false);
            },
            disabled: !hasChanges,
          }}
        />
      </TopicsContainer>
    </form>
  );
}

type ImageUploaderProps = {
  value: string;
  label?: string;
  swapText?: string;
  onChange: (value: string, file: File) => void;
};

const ImageUploader = ({
  value,
  onChange,
  label,
  swapText,
}: ImageUploaderProps) => {
  const onUploadImage = (file: File | null) => {
    if (!file) return;
    const fileUrl = URL.createObjectURL(file);
    onChange(fileUrl, file);
  };
  return (
    <div className="relative w-full h-44">
      <ResourceFileUpload
        onChange={e => onUploadImage(e)}
        label={label}
        value={value}
        className={{
          base: twMerge(
            'w-full h-40',
            value && 'absolute -bottom-1 h-6 opacity-0',
          ),
        }}
      />
      <ConditionalRenderer condition={value}>
        <div className="min-w-full flex flex-col items-center gap-1">
          <img src={value} className="h-40 w-full object-contain" alt={label} />
          <MainButton
            text={swapText || ''}
            color="custom"
            className="text-primary gap-1"
            icon={<SwitchHorizontalIcon />}
          />
        </div>
      </ConditionalRenderer>
    </div>
  );
};

type ChoiceSelectRenderProps = {
  model: User;
};

const ChoiceSelectRender = ({ model }: ChoiceSelectRenderProps) => {
  return <AvatarName user={model} className="gap-1.5" />;
};
