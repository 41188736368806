import Text from '@/components/common/dataDisplay/Text';
import { t } from 'i18next';
import moment from 'moment';
import { Fragment, useMemo } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import useHackathon from '../HackathonGeneral.hooks';
import { getCurrentStage } from '@/utils/hackathon';
import CountdownItem from '@/components/common/CountdownItem';

export default function HackathonCountdown() {
  const { refetchStages, hackathonsStages } = useHackathon();
  const { t } = useTranslation('translation', {
    keyPrefix: 'hackathon',
  });

  const currentStage = getCurrentStage(hackathonsStages);

  const endDate = useMemo(() => moment(currentStage?.endDate), [currentStage]);

  if (!currentStage) return <Fragment />;
  return (
    <div className="flex items-center flex-col gap-1.5">
      <div className="relative flex gap-1">
        <Text
          text={t('stages.' + currentStage.type)}
          className="leading-7 font-600"
        />
        <Text text={t('countdown.finishAt')} className="relative leading-7" />
      </div>
      <Countdown
        key={currentStage.id}
        date={endDate.valueOf()}
        renderer={CountdownRenderer}
        onComplete={() => {
          refetchStages();
        }}
      />
    </div>
  );
}

type CountdownRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
}: CountdownRendererProps) => {
  return (
    <div className="grid grid-cols-4 justify-between text-base-100 gap-2">
      <CountdownItem
        label={t('countdown.days')}
        value={days}
        classNames="bg-hackathon-primary"
      />
      <CountdownItem
        label={t('countdown.hours')}
        value={hours}
        classNames="bg-hackathon-primary"
      />
      <CountdownItem
        label={t('countdown.minutes')}
        value={minutes}
        classNames="bg-hackathon-primary"
      />
      <CountdownItem
        label={t('countdown.seconds')}
        value={seconds}
        classNames="bg-hackathon-primary"
      />
    </div>
  );
};
