import Tag, { TagColor, TagProps } from '@/components/common/dataDisplay/Tag';
import { LoadingIcon } from '@/components/icons';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { MouseEventHandler, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

interface InteractionProps extends Omit<TagProps, 'className' | 'icon'> {
  count: number;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  color: TagColor;
  isLoading?: boolean;
  onClick?: () => void;
  className?: Pick<ClassNameComponent, 'tag' | 'img'>;
}

export default function Interaction({
  count,
  icon,
  color,
  isLoading,
  className,
  onClick,
  ...props
}: InteractionProps) {
  const Icon = isLoading ? LoadingIcon : icon;
  const handleOnClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    onClick?.();
  };
  return (
    <Tag
      text={String(count)}
      icon={<Icon className={twMerge('w-3.5', className?.img)} />}
      className={twMerge(
        '!text-12 py-0.5 px-1.5 gap-0.5 flex-row-reverse !bg-base-100 border',
        onClick && 'cursor-pointer',
        className?.tag,
      )}
      color={color}
      onClick={handleOnClick}
      {...props}
    />
  );
}
