import Carousel from '@/components/common/Carousel';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { ProjectFilters } from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import ProjectSectionHeader from '@/pages/project/ProjectSectionHeader';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import MainButton from '../../common/buttons/MainButton';
import Card from '../cards/Card';
import ProjectCardsFactory, {
  ProjectCardsMode,
} from '../cards/factory/ProjectCardsFactory';

export type ProjectsSections =
  | 'highlights'
  | 'recents'
  | 'viewed'
  | 'me'
  | 'hackathon';

type ProjectCarouselProps = {
  section: ProjectsSections;
  filters?: ProjectFilters;
  route?: string;
};

export default function ProjectCarousel({
  section,
  filters,
  route,
}: ProjectCarouselProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'projects.page' });
  const { t: tCarousel } = useTranslation('translation', {
    keyPrefix: 'projects.carousel',
  });
  const [carrouselFilters, setCarrouselFilters] = useState({});
  const allFilters = { ...filters, ...carrouselFilters };
  const navigate = useNavigate();

  const {
    results: projects,
    isInitialLoading: loading,
    isFetchingNextPage: fetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error: projectsError,
  } = useInfiniteService({
    ...projectsQuerykeys.list(allFilters)._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const projectsErrorDetail = getErrorMessage(projectsError);

  const divClassName = 'flex w-full h-[256px] justify-center items-center';

  const onFilter = (value: ProjectFilters) => {
    setCarrouselFilters(prev => ({ ...prev, ...value }));
  };

  const loadingCards = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, index) => (
        <Card.Skeleton key={index} />
      )),
    [],
  );

  const convertSectionToMode = (
    section: ProjectsSections,
  ): ProjectCardsMode => {
    if (section === 'me') return 'me';
    if (section === 'hackathon') return 'hackathon';
    return 'general';
  };

  return (
    <div className="flex flex-col bg-accent-content/30 rounded-lg border border-neutral-content py-2.5 pl-2.5 gap-2.5">
      <ProjectSectionHeader
        section={section}
        filters={allFilters}
        onFilter={onFilter}
        button={
          <MainButton
            text={tCarousel('seeAll')}
            onClick={() => {
              navigate(route || 'section', {
                state: {
                  filters: allFilters,
                  section,
                },
              });
            }}
            color="custom"
            className={twJoin(
              'font-600 !text-14 text-primary gap-1 flex-row-reverse mr-2.5',
            )}
            icon={
              <ChevronRightIcon
                className={twJoin(
                  'w-4 h-4 ease-in-out transition-all duration-200',
                )}
              />
            }
          />
        }
      />
      <Carousel
        hasNextPage={hasNextPage}
        fetchingNextPage={fetchingNextPage}
        onReachEnd={fetchNextPage}
        hiddenButtons={!projects.length}
      >
        <ConditionalRenderer condition={loading}>
          {loadingCards}
        </ConditionalRenderer>

        <ConditionalRenderer condition={projectsErrorDetail}>
          <div className={divClassName}>
            <Text
              format="rubik-500"
              text={projectsErrorDetail}
              className="text-accent"
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={projects.length}>
          {projects.map(project => (
            <ProjectCardsFactory
              key={project.id}
              project={project}
              filters={allFilters}
              mode={convertSectionToMode(section)}
            />
          ))}
        </ConditionalRenderer>
        <ConditionalRenderer
          condition={!loading && !projectsErrorDetail && !projects.length}
        >
          <div className="flex w-full h-[256px] justify-center items-center">
            <Text text={t('noProjectsFound')} />
          </div>
        </ConditionalRenderer>
      </Carousel>
    </div>
  );
}
