import Skeleton from '@/components/common/Skeleton';
import { twMerge } from 'tailwind-merge';

type CardSkeletonProps = {
  className?: string;
};

export default function CardSkeleton({ className }: CardSkeletonProps) {
  return (
    <Skeleton
      className={twMerge(
        'card rounded-lg min-w-72 xl:min-w-80 h-64',
        className,
      )}
    />
  );
}
