import ProjectCarousel from '@/components/projects/carousel/ProjectCarousel';
import { Fragment } from 'react/jsx-runtime';
import HackathonBanner from './HackathonBanner/HackathonBanner';
import useHackathon, { useHackathonFilters } from './HackathonGeneral.hooks';
import { ProjectFilters } from '@/data/services/projectServices';

type HackathonGeneralProps = {
  filters: ProjectFilters;
};

export default function HackathonGeneral({
  filters: headerFilters,
}: HackathonGeneralProps) {
  const { hackathon, hackathonsStages, isFinish } = useHackathon();

  const hackathonFilters = useHackathonFilters();

  const filters = { ...headerFilters, ...hackathonFilters };

  const route = !isFinish ? 'hackathon' : 'hackathon/ranking';

  if (!hackathon || !hackathonsStages.length) return <Fragment />;

  return (
    <div className="flex flex-col gap-3.5">
      <HackathonBanner
        hackathon={hackathon}
        hackathonStages={hackathonsStages}
      />
      <ProjectCarousel section="hackathon" filters={filters} route={route} />
    </div>
  );
}
