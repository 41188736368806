import Text from '@/components/common/dataDisplay/Text';
import { twMerge } from 'tailwind-merge';

type PublishDateProps = {
  title: String;
  className?: string;
};

export default function Title({ title, className }: PublishDateProps) {
  return (
    <Text
      text={title}
      format="poppins-600"
      className={twMerge('truncate', className)}
    />
  );
}
