import Text from '@/components/common/dataDisplay/Text';
import moment from 'moment';

type PublishDateProps = {
  publishAt: string;
};

export default function PublishDate({ publishAt }: PublishDateProps) {
  const publishDate = moment(publishAt);
  const formated = publishDate.format('DD MMM YYYY');
  return (
    <Text
      text={formated}
      className="text-12 sm:text-14 text-neutral shrink-0"
    />
  );
}
