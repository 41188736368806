import MainButton from '@/components/common/buttons/MainButton';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { ProjectFilters } from '@/data/services/projectServices';
import { StageTypeEnum } from '@/models/Hackathon';
import { Project } from '@/models/Project';
import useHackathon from '@/components/projects/hackathon/HackathonGeneral.hooks';
import { LockClosedIcon } from '@heroicons/react/outline';
import { MouseEventHandler } from 'react';
import { twJoin } from 'tailwind-merge';
import useUpvoting, { useVoteStyles } from './Interactions.hooks';
import { getCurrentStage } from '@/utils/hackathon';
import { useTranslation } from 'react-i18next';

type HackathonVotingProps = {
  project: Project;
  filters?: ProjectFilters;
};

export default function HackathonVoting({
  project,
  filters,
}: HackathonVotingProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hackathon',
  });
  const { hackathonsStages, isLoading } = useHackathon();

  const currentStage = getCurrentStage(hackathonsStages);

  const [upVote, isUpvoting] = useUpvoting(project, filters, false);
  const { upVoted } = project;

  const { color, text, Icon } = useVoteStyles(upVoted);

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    upVote();
  };
  const isSubmissionStage = currentStage?.type === StageTypeEnum.SUBMISSION;
  return (
    <ConditionalWrapper
      wrapper={<Tooltip text={t('votingSoon')} />}
      condition={isSubmissionStage}
    >
      <MainButton
        dataTestId="hackathonVote"
        color="custom"
        className={twJoin('flex-row-reverse gap-1 font-600 !text-14', color)}
        text={text}
        icon={isSubmissionStage ? <LockClosedIcon /> : <Icon />}
        onClick={handleClick}
        loading={isUpvoting || isLoading}
        disabled={isSubmissionStage}
      />
    </ConditionalWrapper>
  );
}
