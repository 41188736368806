import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import useListService from '@/data/hook/useListService';
import { ProjectFilters } from '@/data/services/projectServices';
import {
  hackathonsQueryKeys,
  hackathonsStagesQueryKeys,
} from '@/data/services/querykeys';
import { isStudent, isSuperAdmin } from '@/functions/auth';
import { FlagTypeEnum } from '@/models/Flag';
import { StageTypeEnum } from '@/models/Hackathon';
import { getCurrentStage } from '@/utils/hackathon';
import moment from 'moment';

const currentDate = moment().toISOString();
const useHackathon = () => {
  const { results: hackathons, isInitialLoading } = useListService({
    ...hackathonsQueryKeys.list({ date: currentDate }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const currentHackathon = hackathons.at(0);

  const { results: lastHackathon } = useListService({
    ...hackathonsQueryKeys.list({ lastHackathon: true }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !currentHackathon && !isInitialLoading,
  });

  const hackathon = currentHackathon || lastHackathon.at(0);

  const {
    results: hackathonsStages,
    isInitialLoading: isLoadingStages,
    refetch: refetchStages,
  } = useListService({
    ...hackathonsStagesQueryKeys.list({ hackathon: hackathon?.id }),
    enabled: !!hackathon,
  });

  const isFinish = hackathonsStages.every(stage =>
    moment().isAfter(stage.endDate),
  );

  return {
    hackathon,
    hackathonsStages,
    currentStage: getCurrentStage(hackathonsStages),
    isFinish,
    isLoading: isInitialLoading || isLoadingStages,
    refetchStages,
  };
};

export default useHackathon;

export const useHackathonFilters = (): ProjectFilters => {
  const { hackathon, hackathonsStages, isFinish } = useHackathon();
  const { user } = useAuth();
  const currentState = getCurrentStage(hackathonsStages);

  if (!hackathon) return {};
  const falseyToUndefined = (value: any) => value || undefined;
  const shunfle =
    isStudent(user?.userType) &&
    currentState?.type !== StageTypeEnum.SUBMISSION;
  const byUpVotes = isSuperAdmin(user?.userType) || isFinish;
  const flagsType = currentState?.type === StageTypeEnum.FINAL_VOTING;

  return {
    flagsId: [hackathon.flagId],
    flagsType: falseyToUndefined(flagsType && [FlagTypeEnum.FINALIST]),
    byUpVotes: falseyToUndefined(byUpVotes && 'more'),
    shunfle: falseyToUndefined(shunfle),
  };
};
