import { Fragment } from 'react/jsx-runtime';
import CreateGeneralProjectCard from './CreateGeneralCard';
import CreateHackathonProjectCard from './CreateHackathonCard';
import CreateMeProjectCard from './CreateMeCard';
import ProjectCardProps from './ProjectCardProps';
import useHackathon from '@/components/projects/hackathon/HackathonGeneral.hooks';

export type ProjectCardsMode = 'me' | 'hackathon' | 'general';

type ProjectCardMode = {
  mode: ProjectCardsMode;
};

type ProjectCardsFactoryProps = ProjectCardProps & ProjectCardMode;

export default function ProjectCardsFactory({
  mode,
  ...projectCardProps
}: ProjectCardsFactoryProps) {
  const { hackathon } = useHackathon();

  const isHackathonProject = projectCardProps.project.flags
    ?.map(flag => flag.id)
    .includes(Number(hackathon?.flagId));

  if (isHackathonProject || mode === 'hackathon') {
    return <CreateHackathonProjectCard {...projectCardProps} />;
  }

  if (mode === 'general') {
    return <CreateGeneralProjectCard {...projectCardProps} />;
  }

  if (mode === 'me') {
    return <CreateMeProjectCard {...projectCardProps} />;
  }

  return <Fragment />;
}
