import useAuth from '@/data/hook/useAuth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import { useTranslation } from 'react-i18next';

export default function useOwnerName(user: User) {
  const { user: userAuth } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });
  const isYou = user.id === userAuth?.id;
  const fullname = isYou ? t('you') : handleUserFullName(user);
  return fullname;
}
