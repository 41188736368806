import AstroImage from '@/components/common/AstroImage';
import Text from '@/components/common/dataDisplay/Text';
import Hackathon from '@/models/Hackathon';
import { Breakpoints } from '@/utils/Breakpoint';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

type HackathonBannerInformationProps = {
  hackathon: Hackathon;
};

export default function HackathonBannerInformation({
  hackathon,
}: HackathonBannerInformationProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hackathon.banner',
  });
  const isXs = useMediaQuery({
    minWidth: Breakpoints.xs,
  });
  return (
    <div className="flex gap-5 items-start xs:items-center">
      <AstroImage src="/images/hackathon.png" width={isXs ? 171 : 55} />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-0">
          <Text
            text={t('tematic')}
            className="text-16 sm:text-28 font-poppins font-600 text-hackathon-secondary"
          />
          <Text
            text={hackathon.title}
            className="text-16 sm:text-28 font-poppins font-600 text-hackathon-primary"
          />
        </div>
        <Text text={t('winnersPhrase')} className="text-14 sm:text-16" />
      </div>
    </div>
  );
}
