import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import TextInput from '@/components/common/dataInput/TextInput';
import { DownloadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectFields } from '../form/ProjectForm';

type ProjectImportProps = {
  onImport: (url: string) => void;
  projectUrl?: string;
  control: Control<ProjectFields>;
  isLoading?: boolean;
};

export default function ProjectImport({
  projectUrl,
  isLoading,
  control,
  onImport,
}: ProjectImportProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.fields',
  });
  const {
    fieldState: { error },
  } = useController({
    control,
    name: 'projectUrl',
    rules: {
      required: t('required'),
    },
  });

  const [value, setValue] = useState(projectUrl);
  return (
    <div className="bg-secondary-content p-2.5 gap-2.5 rounded-lg flex flex-col w-full max-w-[500px]">
      <Text text={t('importYourProject')} className="text-secondary font-500" />
      <div className="flex flex-col md:flex-row gap-2 items-end">
        <TextInput
          value={value || ''}
          onChange={e => setValue(e.target.value)}
          placeholder={t('projectUrl.placeholder')}
          errorLabelText={error?.message}
          className={{ base: 'flex-grow' }}
        />
        <MainButton
          text={t('projectUrl.label')}
          onClick={() => onImport(value || '')}
          disabled={!value || value === projectUrl}
          icon={<DownloadIcon />}
          loading={isLoading}
          className="mb-0.5"
        />
      </div>
    </div>
  );
}
