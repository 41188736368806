import Card from '../Card';
import ProjectCardProps from './ProjectCardProps';

export default function CreateMeProjectCard({
  project,
  filters,
  className,
}: ProjectCardProps) {
  return (
    <Card key={project.id} project={project} className={className?.base}>
      <Card.Interactions project={project} filters={filters} />
      <Card.Banner url={project.image} />
      <Card.FlexGroup>
        <Card.Title title={project.title} className="flex-grow" />
        <Card.OwnerActions project={project} filters={filters} />
      </Card.FlexGroup>
      <Card.Tags tool={project.tool} flags={project.flags} />
      <Card.FlexGroup>
        <Card.PublushDate publishAt={project.createdAt} />
        <Card.Owners owners={project.users} />
      </Card.FlexGroup>
    </Card>
  );
}
