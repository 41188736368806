import ToggleGroup, {
  ToggleButton,
  ToggleButtonSize,
} from '@/components/common/dataInput/ToggleGroup';
import { CourseTypeEnum } from '@/models/Course';
import { CarouselFilterProps } from './CarouselFilterProps';
import { useTranslation } from 'react-i18next';

type CourseTypeFilterValue = CourseTypeEnum | 'all';

type CourseTypeFilterProps = CarouselFilterProps & {
  size?: ToggleButtonSize;
};

const CourseTypeFilter = ({
  filter,
  onFilter,
  size,
}: CourseTypeFilterProps) => {
  const { t: tCourseType } = useTranslation('translation', {
    keyPrefix: 'filters',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.page',
  });
  const onChange = (courseType: CourseTypeFilterValue) => {
    if (courseType === 'all') {
      onFilter?.({ courseType: [] });
      return;
    }
    onFilter?.({
      courseType: [courseType],
    });
  };
  const buttons: ToggleButton<CourseTypeFilterValue>[] = [
    {
      text: t('all'),
      value: 'all',
      className: {
        regular: 'border-accent text-accent enabled:hover:bg-accent-content',
        active: 'bg-accent border-accent',
      },
    },
    {
      text: tCourseType(CourseTypeEnum.CTRL_KIDS),
      value: CourseTypeEnum.CTRL_KIDS,
      className: {
        regular: 'border-warning text-warning enabled:hover:bg-warning-content',
        active: 'bg-warning border-warning',
      },
    },
    {
      text: tCourseType(CourseTypeEnum.CTRL_TEENS),
      value: CourseTypeEnum.CTRL_TEENS,
      className: {
        regular: 'text-primary',
      },
    },
    {
      text: tCourseType(CourseTypeEnum.CTRL_YOUNG),
      value: CourseTypeEnum.CTRL_YOUNG,
      className: {
        regular:
          'border-secondary text-secondary enabled:hover:bg-secondary-content',
        active: 'bg-secondary border-secondary',
      },
    },
  ];
  return (
    <ToggleGroup
      buttons={buttons}
      onChange={onChange}
      value={filter?.courseType?.[0] || 'all'}
      size={size || 'small'}
      exclusive
    />
  );
};

export default CourseTypeFilter;
