import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import useHackathon from '@/components/projects/hackathon/HackathonGeneral.hooks';
import Card from '../Card';
import ProjectCardProps from './ProjectCardProps';

export default function CreateHackathonProjectCard({
  project,
  filters,
  className,
}: ProjectCardProps) {
  const { user } = useAuth();
  const { isFinish } = useHackathon();
  return (
    <Card key={project.id} project={project} className={className?.base}>
      <ConditionalRenderer condition={isSuperAdmin(user?.userType) || isFinish}>
        <Card.Interactions project={project} />
      </ConditionalRenderer>
      <Card.Banner url={project.image} />
      <Card.FlexGroup>
        <Card.Title title={project.title} className="flex-grow" />
        <ConditionalRenderer condition={!isFinish}>
          <Card.HackathonVoting project={project} filters={filters} />
        </ConditionalRenderer>
      </Card.FlexGroup>
      <Card.Tags tool={project.tool} flags={project.flags} />
      <Card.FlexGroup>
        <Card.PublushDate publishAt={project.createdAt} />
        <Card.Owners owners={project.users} />
      </Card.FlexGroup>
    </Card>
  );
}
