import Tag from '@/components/common/dataDisplay/Tag';
import { CourseTypeEnum } from '@/models/Course';
import Flag from '@/models/Flag';
import { SimpleTag } from '@/models/Tag';
import Tool from '@/models/Tool';
import transformer from '@/utils/transformer';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type ProjectTagsProps = {
  tool?: Tool;
  flags?: Flag[];
};
export function ProjectTags({ tool, flags = [] }: ProjectTagsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'courseType' });
  const adapterTool = (tool?: Tool) => {
    if (!tool) return;
    return {
      name: tool.name,
      color: tool.color,
    };
  };

  const courseTypeClassNames: Record<string, string> = {
    [t(CourseTypeEnum.CTRL_KIDS)]: 'bg-warning border-warning',
    [t(CourseTypeEnum.CTRL_YOUNG)]: 'bg-secondary border-secondary',
    [t(CourseTypeEnum.CTRL_TEENS)]: 'bg-primary border-primary',
  };

  function isCourseFlag(flag: Pick<Flag, 'name'>) {
    const courseTypes = Object.values<string>(CourseTypeEnum);
    return courseTypes.includes(flag.name);
  }

  const courseTranform = (flag: Flag): Flag => {
    if (isCourseFlag(flag)) return { ...flag, name: t(flag.name) };
    return flag;
  };

  const flagsTranformed = transformer(flags, [courseTranform]);

  const buildTags = ({ tool }: ProjectTagsProps) => {
    return compact([adapterTool(tool), ...flagsTranformed]);
  };
  const tags: Omit<SimpleTag, 'id'>[] = buildTags({ tool });
  return (
    <div className="flex gap-2 flex-wrap">
      {tags.map(tag => (
        <Tag
          key={tag.name}
          style={{
            backgroundColor: tag.color,
          }}
          className={twMerge(
            '!text-14 leading-none py-0.5 px-1.5 font-400 text-base-100',
            courseTypeClassNames[tag.name] ||
              (!tag.color ? 'bg-secondary border-secondary' : ''),
          )}
          color="custom"
          text={tag.name}
          tooltip={tag.description || ''}
        />
      ))}
    </div>
  );
}
