import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Checkbox from '@/components/common/dataInput/Checkbox';
import { DashedCircleIcon } from '@/components/icons';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { StageTypeEnum } from '@/models/Hackathon';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import useHackathon from './HackathonGeneral.hooks';
import Text from '@/components/common/dataDisplay/Text';

function hasImageOrVideoInDescription(description?: string): boolean {
  if (!description) return false;
  const regex = /<img[^>]*>|<video[^>]*>/i;
  return regex.test(description);
}

function hasDescription(description?: string): boolean {
  if (!description) return false;
  const regexTextOutsideTags =
    /(?<!<img[^>]*>)(?<!<video[^>]*>)([^<]+)(?!<\/a>)/i; // Regex para verificar texto fora de <img> ou <video>
  return regexTextOutsideTags.test(description);
}

type ProjectHackathonFormProps = {
  isHackathon: boolean;
  setIsHackathon: (value: boolean) => void;
  description?: string;
};

export default function ProjectHackathonForm({
  isHackathon,
  description,
  setIsHackathon,
}: ProjectHackathonFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.fields',
  });

  const hasImageOrVideo = hasImageOrVideoInDescription(description);

  const hasLink = hasDescription(description);

  const { currentStage } = useHackathon();

  if (currentStage?.type !== StageTypeEnum.SUBMISSION) return <Fragment />;
  return (
    <div className="bg-primary-content p-2.5 gap-2.5 rounded-lg flex flex-col w-full max-w-[500px]">
      <Checkbox
        className={{
          input: 'checkbox-lg w-4 h-4 rounded-sm',
          label: '!text-primary font-500 text-16',
        }}
        checked={isHackathon}
        onChange={e => setIsHackathon(e.target.checked)}
        label={t('isHackathon', {
          context: String(isHackathon),
        })}
      />
      <ConditionalRenderer condition={isHackathon}>
        <div className="flex gap-2">
          <CheckItem checked={hasLink} label={t('document')} />
          <CheckItem checked={hasImageOrVideo} label={t('imageOrVideo')} />
        </div>
      </ConditionalRenderer>
    </div>
  );
}

type CheckItemProps = {
  checked: boolean;
  label: string;
};

const CheckItem = ({ checked, label }: CheckItemProps) => {
  const Icon = checked ? CheckCircleIcon : DashedCircleIcon;
  return (
    <div className="flex gap-2 items-center">
      <Icon className="w-5 h-5 text-secondary" />
      <Text text={label} />
    </div>
  );
};
