import { Avatar } from '@/components/common/dataDisplay/Avatar';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import User from '@/models/User';
import useOwnerName from './Owners.hook';

type OwnersProps = {
  owners: User[];
};
export default function Owners({ owners }: OwnersProps) {
  return (
    <ul className="flex gap-1 flex-wrap justify-end">
      {owners.map(user => (
        <Owner user={user} key={user.id} />
      ))}
    </ul>
  );
}

type OwnerProps = {
  user: User;
};

const Owner = ({ user }: OwnerProps) => {
  const fullname = useOwnerName(user);
  return (
    <Tooltip
      text={fullname}
      classNameContainer="overflow-x-hidden"
      color="accent"
      key={user.id}
    >
      <Avatar
        className="gap-1 text-base-content text-14 text-right overflow-x-hidden"
        userId={user.id}
      />
    </Tooltip>
  );
};
