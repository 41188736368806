import AstroImage from '@/components/common/AstroImage';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';

export default function Banner({ url }: { url?: string }) {
  const renderLoading = () => (
    <Skeleton className="rounded-md flex w-full h-40 bg-neutral-content" />
  );

  return (
    <ConditionalRenderer
      condition={url}
      fallback={<div className="h-40 flex w-full bg-neutral-content" />}
    >
      <AstroImage
        className="object-cover flex w-full self-center rounded-md h-40"
        src={url}
        validation={{
          renderLoading,
        }}
      />
    </ConditionalRenderer>
  );
}
